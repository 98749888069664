import { Context } from "../../";

const doSignIn = async (context: Context) => {
  const { state, effects } = context;

  state.app.isLoading = true;

  await effects.user.signIn();

  state.app.isLoading = false;
};

const doSignOut = async (context: Context) => {
  const { state, effects } = context;

  state.app.isLoading = true;

  const result = await effects.user.signOut();

  if (result.ok) {
    state.user.data = null;
  }

  state.app.isLoading = false;
};

export const user = {
  doSignIn,
  doSignOut,
};
