/* eslint-disable react-hooks/exhaustive-deps */

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SubjectIcon from "@material-ui/icons/Subject";
import { DateTimePicker } from "@material-ui/pickers";
import orderBy from "lodash/orderBy";
import { mapOr } from "option-t/esm/PlainOption";
import React, { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import { EntryNew } from "stashify-models/types/entry";
import { Stash } from "stashify-models/types/stash";
import { now } from "stashify-utils/src/date";
import { APP_NAME } from "../../config";
import { generateNewEntry } from "../../utils";
import { formatDate } from "../../utils/date";

export const EntryFormLite = (props: {
  stash: Stash[];
  onSubmit: (entry: EntryNew) => void;
}) => {
  const { stash } = props;

  const [entry, setEntry] = useState<EntryNew>(() => generateNewEntry());

  const [customNotes, setCustomNotes] = useState(false);
  const [customDate, setCustomDate] = useState(false);

  const [lastStashId, setLastStashId] = useLocalStorage(
    `${APP_NAME}_lastStashId`,
    ""
  );

  const onSubmit = () => {
    props.onSubmit({
      ...entry,
      createdAt: customDate ? entry.createdAt : now(),
    });
  };

  useEffect(() => {
    const stashId = stash.find((i) => i.$id === lastStashId)?.$id || "";
    setEntry(generateNewEntry(stashId));
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormControl variant="filled" fullWidth size="small">
          <InputLabel>Stash</InputLabel>
          <Select
            fullWidth
            value={entry.stashId}
            onChange={(e) => {
              if (typeof e.target.value === "string") {
                setEntry({
                  ...entry,
                  stashId: e.target.value,
                });
                setLastStashId(e.target.value);
              }
            }}
          >
            {orderBy(stash, ["title"], ["asc"]).map((i) => (
              <MenuItem key={i.$id} value={i.$id}>
                {i.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {customNotes && (
        <Grid item xs={12}>
          <TextField
            label="Notes"
            variant="filled"
            fullWidth
            multiline
            rows="4"
            size="small"
            value={entry.notes || ""}
            onChange={(e) => {
              setEntry({
                ...entry,
                notes: e.target.value || null,
              });
            }}
          />
        </Grid>
      )}
      {customDate && (
        <Grid item xs={12}>
          <DateTimePicker
            size="small"
            label="Date"
            variant="dialog"
            inputVariant="filled"
            showTodayButton
            todayLabel="Now"
            fullWidth
            value={entry.createdAt}
            labelFunc={() =>
              mapOr(formatDate(entry.createdAt), "N/A", (date) => date)
            }
            onChange={(e) => {
              if (typeof e == "object" && e != null)
                setEntry({
                  ...entry,
                  createdAt: e.toISOString(),
                });
            }}
          />
        </Grid>
      )}
      <Grid item xs={9}>
        <IconButton onClick={() => setCustomNotes(!customNotes)}>
          <SubjectIcon
            fontSize="small"
            color={customNotes ? "primary" : "action"}
          />
        </IconButton>
        <IconButton onClick={() => setCustomDate(!customDate)}>
          <ScheduleIcon
            fontSize="small"
            color={customDate ? "primary" : "action"}
          />
        </IconButton>
      </Grid>
      <Grid item xs={3} container alignItems="center">
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={() => onSubmit()}
          // disabled={!validateEntry(entry)}
        >
          add
        </Button>
      </Grid>
    </Grid>
  );
};
