import { Array, Number, Record, String } from "runtypes";

export const RtOrderNew = Record({
  status: Number,
  orderedAt: String,
  store: String,
  seller: String,
  item: String,
  amount: String,
  price: Number,
  note: String,
  arrivedAt: String,
});

export const RtOrder = Record({
  ...RtOrderNew.fields,
  $id: String,
  createdAt: String,
  lastUpdatedAt: String,
});

export const RtOrderRequest = Record({
  documents: Array(RtOrder),
  sum: Number,
});
