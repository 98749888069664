import { css } from "@emotion/css";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { useActions } from "../../store";

export const FloatingActionButton = () => {
  const { toggle } = useActions().app.bottomDrawer;
  return (
    <div
      className={css`
        position: fixed;
        bottom: 64px;
        right: 64px;
        @media (max-width: 960px) {
          bottom: 32px;
          right: 32px;
        }
      `}
    >
      <Fab color="primary" size="medium" onClick={() => toggle(true)}>
        <AddIcon />
      </Fab>
    </div>
  );
};
