import { createErr, createOk } from "option-t/cjs/PlainResult";
import { RtUser } from "stashify-models/runtypes/user";
import { api, auth } from "../../../services/Appwrite";

export const user = {
  get: async () => {
    try {
      const response = await api.account.get();
      const user = RtUser.check(response);
      return createOk(user);
    } catch (error) {
      console.log(error);
      return createErr(error);
    }
  },
  signIn: async () => {
    try {
      await auth.createOAuth2Session();
      return createOk(null);
    } catch (error) {
      console.log(error);
      return createErr(error);
    }
  },
  signOut: async () => {
    try {
      await auth.deleteSessions();
      return createOk(null);
    } catch (error) {
      console.log(error);
      return createErr(error);
    }
  },
};
