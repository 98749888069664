import { IContext } from "overmind";
import {
  createActionsHook,
  createEffectsHook,
  createReactionHook,
  createStateHook,
} from "overmind-react";
import * as actionsApp from "./actions/app";
import * as actionsData from "./actions/data";
import * as actionsUser from "./actions/user";
import * as effectsData from "./effects/data";
import * as effectsUser from "./effects/user";
import { state } from "./state";

export const config = {
  state,
  actions: {
    ...actionsApp,
    ...actionsData,
    ...actionsUser,
  },
  effects: {
    ...effectsData,
    ...effectsUser,
  },
};

export type Context = IContext<{
  state: typeof config.state;
  actions: typeof config.actions;
  effects: typeof config.effects;
}>;

export const useAppState = createStateHook<Context>();
export const useActions = createActionsHook<Context>();
export const useEffects = createEffectsHook<Context>();
export const useReaction = createReactionHook<Context>();
