import { createErr, createOk } from "option-t/cjs/PlainResult";
import { api } from "services/Appwrite";
import {
  APP_API_COLLECTION_ID_ENTRIES,
  APP_API_COLLECTION_ID_ORDERS,
  APP_API_COLLECTION_ID_STASH,
} from "services/Appwrite/config";
import { RtEntry, RtEntryRequest } from "stashify-models/runtypes/entry";
import { RtOrder, RtOrderRequest } from "stashify-models/runtypes/order";
import { RtStash, RtStashRequest } from "stashify-models/runtypes/stash";
import { Entry, EntryNew } from "stashify-models/types/entry";
import { Order, OrderNew } from "stashify-models/types/order";
import { Stash, StashNew } from "stashify-models/types/stash";
import { now } from "stashify-utils/src/date";

export const data = {
  entries: {
    get: async () => {
      try {
        const response = await api.database.listDocuments(
          APP_API_COLLECTION_ID_ENTRIES,
          [],
          100,
          0,
          "createdAt",
          "DESC"
        );
        const result = RtEntryRequest.check(response);
        return createOk(result);
      } catch (error) {
        console.log(error);
        return createErr(error);
      }
    },
    add: async (data: EntryNew) => {
      try {
        const response = await api.database.createDocument(
          APP_API_COLLECTION_ID_ENTRIES,
          "unique()",
          {
            ...data,
            lastUpdatedAt: now(),
          }
        );
        const result = RtEntry.check(response);
        return createOk(result);
      } catch (error) {
        return createErr(error);
      }
    },
    update: async (data: Entry) => {
      try {
        const { $id, lastUpdatedAt, ...rest } = data;
        const response = await api.database.updateDocument(
          APP_API_COLLECTION_ID_ENTRIES,
          data.$id,
          {
            ...rest,
            lastUpdatedAt: now(),
          }
        );
        const result = RtEntry.check(response);
        return createOk(result);
      } catch (error) {
        return createErr(error);
      }
    },
    delete: async (data: Entry) => {
      try {
        await api.database.deleteDocument(
          APP_API_COLLECTION_ID_ENTRIES,
          data.$id
        );
        return createOk(true);
      } catch (error) {
        return createErr(error);
      }
    },
  },
  stash: {
    get: async () => {
      try {
        const response = await api.database.listDocuments(
          APP_API_COLLECTION_ID_STASH,
          [],
          100,
          0,
          "title",
          "ASC"
        );
        const result = RtStashRequest.check(response);
        return createOk(result);
      } catch (error) {
        console.log(error);
        return createErr(error);
      }
    },
    add: async (data: StashNew) => {
      try {
        const response = await api.database.createDocument(
          APP_API_COLLECTION_ID_STASH,
          "unique()",
          {
            ...data,
            createdAt: now(),
            lastUpdatedAt: now(),
          }
        );
        const result = RtStash.check(response);
        return createOk(result);
      } catch (error) {
        return createErr(error);
      }
    },
    update: async (data: Stash) => {
      try {
        const { $id, createdAt, lastUpdatedAt, ...rest } = data;
        const response = await api.database.updateDocument(
          APP_API_COLLECTION_ID_STASH,
          data.$id,
          {
            ...rest,
            lastUpdatedAt: now(),
          }
        );
        const result = RtStash.check(response);
        return createOk(result);
      } catch (error) {
        return createErr(error);
      }
    },
    delete: async (data: Stash) => {
      try {
        await api.database.deleteDocument(
          APP_API_COLLECTION_ID_STASH,
          data.$id
        );
        return createOk(true);
      } catch (error) {
        return createErr(error);
      }
    },
  },
  orders: {
    get: async () => {
      try {
        const response = await api.database.listDocuments(
          APP_API_COLLECTION_ID_ORDERS,
          [],
          100,
          0,
          "createdAt",
          "DESC"
        );
        const result = RtOrderRequest.check(response);
        return createOk(result);
      } catch (error) {
        console.log(error);
        return createErr(error);
      }
    },
    add: async (data: OrderNew) => {
      try {
        const response = await api.database.createDocument(
          APP_API_COLLECTION_ID_ORDERS,
          "unique()",
          {
            ...data,
            createdAt: now(),
            lastUpdatedAt: now(),
          }
        );
        const result = RtOrder.check(response);
        return createOk(result);
      } catch (error) {
        return createErr(error);
      }
    },
    update: async (order: Order) => {
      try {
        const { $id, createdAt, lastUpdatedAt, ...rest } = order;
        const response = await api.database.updateDocument(
          APP_API_COLLECTION_ID_ORDERS,
          order.$id,
          {
            ...rest,
            lastUpdatedAt: now(),
          }
        );
        const result = RtOrder.check(response);
        return createOk(result);
      } catch (error) {
        return createErr(error);
      }
    },
    delete: async (order: Order) => {
      try {
        await api.database.deleteDocument(
          APP_API_COLLECTION_ID_ORDERS,
          order.$id
        );
        return createOk(true);
      } catch (error) {
        return createErr(error);
      }
    },
  },
};
