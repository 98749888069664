import { StateNotificationType } from "stashify-models/types";
import { Context } from "../../";

export const app = {
  onInitializeOvermind: async (context: Context) => {
    const { state, effects, actions } = context;
    const result = await effects.user.get();
    if (result.ok) {
      await actions.data.entries.get();
      await actions.data.stash.get();
      actions.data.orders.get();
      state.user.data = result.val;
    }
    state.app.isInitialized = true;
  },
  notification: {
    show: async (
      context: Context,
      payload: { message: string; type: StateNotificationType }
    ) => {
      const { state } = context;
      state.app.notification.show = true;
      state.app.notification.message = payload.message;
      state.app.notification.type = payload.type;
    },
    close: async (context: Context) => {
      const { state } = context;
      state.app.notification.show = false;
    },
  },
  confirmDialog: {
    open: (context: Context, payload: { onConfirm: () => any }) => {
      const { state } = context;
      state.app.confirmDialog = {
        open: true,
        onConfirm: payload.onConfirm,
      };
    },
    onCancel: (context: Context) => {
      const { state } = context;
      state.app.confirmDialog.open = false;
    },
    onConfirm: (context: Context) => {
      const { state } = context;
      state.app.confirmDialog.onConfirm();
      state.app.confirmDialog.open = false;
    },
  },
  bottomDrawer: {
    toggle: (context: Context, payload: boolean) => {
      const { state } = context;
      state.app.bottomDrawer.open = payload;
    },
  },
};
