import { isToday, parseISO } from "date-fns";
import { createNone, createSome, Option } from "option-t/esm/PlainOption";
import { Entry, EntryNew } from "stashify-models/types/entry";
import { OrderNew } from "stashify-models/types/order";
import { Stash, StashNew } from "stashify-models/types/stash";
import { now } from "stashify-utils/src/date";

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

// ENTRY
export const generateNewEntry = (stashId: string = ""): EntryNew => ({
  stashId,
  notes: null,
  createdAt: now(),
});

// STASH
export const generateStashObj = (): StashNew => ({
  title: "",
  stock: null,
  notes: null,
});

// ORDER
export const generateOrderObj = (): OrderNew => ({
  orderedAt: now(),
  status: 0,
  seller: "",
  store: "",
  item: "",
  amount: "",
  price: 0,
  note: "",
  arrivedAt: "",
});

export const getStashById = (stashList: Stash[], id: string): Option<Stash> => {
  const result = stashList.find((stash) => stash.$id === id) || null;
  return result ? createSome(result) : createNone();
};

export const filterEntriesByToday = (entries: Entry[]): Entry[] => {
  return entries.filter((entry) => isToday(parseISO(entry.createdAt)));
};
