import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAppState } from "../../store";

export const AuthenticatedRoute: React.FC<RouteProps> = ({
  children,
  ...rest
}: any) => {
  const {
    user: { isAuthenticated },
  } = useAppState();
  return (
    <Route
      {...rest}
      render={() => (isAuthenticated ? children : <Redirect to="/login" />)}
    />
  );
};
