import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
import { APP_NAME } from "../../config";
import { useAppState } from "../../store";

const useStyles = makeStyles((theme) => ({
  title: {
    letterSpacing: 0.75,
    fontWeight: "bold",
  },
  menu: {
    display: "flex",
    marginLeft: "auto",
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export const AppHeader = () => {
  const classes = useStyles();
  const history = useHistory();
  const { app, user } = useAppState();

  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Button onClick={() => history.push("/")}>
            <Typography className={classes.title}>{APP_NAME}</Typography>
          </Button>
          {app.isInitialized && (
            <div className={classes.menu}>
              {user.data === null ? (
                <Button onClick={() => history.push("/login")}>login</Button>
              ) : (
                <>
                  <Button onClick={() => history.push("/entries")}>
                    entries
                  </Button>
                  <Button onClick={() => history.push("/stash")}>stash</Button>
                  <Button onClick={() => history.push("/orders")}>
                    orders
                  </Button>
                  <IconButton
                    color="inherit"
                    onClick={() => history.push("/account")}
                  >
                    <Avatar
                      alt={user.data.name}
                      src={user.data.prefs.photoURL}
                      className={classes.avatar}
                    />
                  </IconButton>
                </>
              )}
            </div>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};
