import { derived } from "overmind";
import { StateNotificationType } from "stashify-models/types";
import { Entry } from "stashify-models/types/entry";
import { Order } from "stashify-models/types/order";
import { Stash } from "stashify-models/types/stash";
import { User } from "stashify-models/types/user";

export type StateApp = {
  isInitialized: boolean;
  isLoading: boolean;
  notification: {
    show: boolean;
    message: string;
    type: StateNotificationType;
  };
  confirmDialog: {
    open: boolean;
    onConfirm: () => any;
  };
  bottomDrawer: {
    open: boolean;
  };
};

export type StateUser = {
  isAuthenticated: boolean;
  data: User | null;
};

export type StateEntry = {
  isLoading: boolean;
  data: Entry[];
  sum: number;
};

export type StateStash = {
  isLoading: boolean;
  data: Stash[];
  sum: number;
};

export type StateOrder = {
  isLoading: boolean;
  data: Order[];
  sum: number;
};

export type State = {
  app: StateApp;
  user: StateUser;
  entries: StateEntry;
  stash: StateStash;
  orders: StateOrder;
};

export const state: State = {
  app: {
    isInitialized: false,
    isLoading: false,
    notification: {
      show: false,
      message: "",
      type: "info",
    },
    confirmDialog: {
      open: false,
      onConfirm: () => {},
    },
    bottomDrawer: {
      open: false,
    },
  },
  user: {
    isAuthenticated: derived((user: StateUser) => user.data !== null),
    data: null,
  },
  entries: {
    isLoading: true,
    data: [],
    sum: 0,
  },
  stash: {
    isLoading: true,
    data: [],
    sum: 0,
  },
  orders: {
    isLoading: true,
    data: [],
    sum: 0,
  },
};
