import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { useActions, useAppState } from "../../store";

// TODO: add title and text to store
export const ConfirmDialog = () => {
  const {
    app: {
      confirmDialog: { onCancel, onConfirm },
    },
  } = useActions();
  const { open } = useAppState().app.confirmDialog;

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? onCancel() : null
        }
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCancel()}>cancel</Button>
          <Button onClick={() => onConfirm()} color="secondary">
            delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
