import { createErr, createOk } from "option-t/cjs/PlainResult";
import { Entry, EntryNew } from "stashify-models/types/entry";
import { Order, OrderNew } from "stashify-models/types/order";
import { Stash, StashNew } from "stashify-models/types/stash";
import { Context } from "../../";

export const data = {
  entries: {
    get: async (context: Context) => {
      const { state, effects } = context;
      const result = await effects.data.entries.get();
      if (!result.ok) return;
      state.entries = {
        isLoading: false,
        data: result.val.documents,
        sum: result.val.sum,
      };
    },
    add: async (context: Context, payload: EntryNew) => {
      const { state, effects, actions } = context;
      state.app.isLoading = true;

      const result = await effects.data.entries.add(payload);

      if (result.ok) {
        state.entries.data.push(result.val);
      }

      state.app.isLoading = false;

      if (result.ok) {
        actions.app.notification.show({
          message: "Successfully 🤙",
          type: "success",
        });
        return createOk(result.val);
      } else {
        actions.app.notification.show({
          message: "Failed 🤷‍♂️",
          type: "error",
        });
        return createErr(result.err);
      }
    },
    update: async (context: Context, payload: Entry) => {
      const { state, effects, actions } = context;
      state.app.isLoading = true;

      const result = await effects.data.entries.update(payload);

      if (result.ok) {
        state.entries.data = [
          ...state.entries.data.filter((i) => i.$id !== payload.$id),
          result.val,
        ];
      }

      state.app.isLoading = false;

      if (result.ok) {
        actions.app.notification.show({
          message: "Successfully 🤙",
          type: "success",
        });
        return createOk(result.val);
      } else {
        actions.app.notification.show({
          message: "Failed 🤷‍♂️",
          type: "error",
        });
        return createErr(result.err);
      }
    },
    delete: async (context: Context, payload: Entry) => {
      const { state, effects, actions } = context;

      state.app.isLoading = true;

      const result = await effects.data.entries.delete(payload);

      if (result.ok) {
        state.entries.data = [
          ...state.entries.data.filter((i) => i.$id !== payload.$id),
        ];
      }

      state.app.isLoading = false;

      if (result.ok) {
        actions.app.notification.show({
          message: "Successfully 🤙",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed 🤷‍♂️",
          type: "error",
        });
        return createErr(null);
      }
    },
  },
  stash: {
    get: async (context: Context) => {
      const { state, effects } = context;
      const result = await effects.data.stash.get();
      if (!result.ok) return;
      state.stash = {
        isLoading: false,
        data: result.val.documents,
        sum: result.val.sum,
      };
    },
    add: async (context: Context, payload: StashNew) => {
      const { state, effects, actions } = context;
      state.app.isLoading = true;

      const result = await effects.data.stash.add(payload);

      if (result.ok) {
        state.stash.data.push(result.val);
      }

      state.app.isLoading = false;

      if (result.ok) {
        actions.app.notification.show({
          message: "Successfully 🤙",
          type: "success",
        });
        return createOk(result.val);
      } else {
        actions.app.notification.show({
          message: "Failed 🤷‍♂️",
          type: "error",
        });
        return createErr(result.err);
      }
    },
    update: async (context: Context, payload: Stash) => {
      const { state, effects, actions } = context;
      state.app.isLoading = true;

      const result = await effects.data.stash.update(payload);

      if (result.ok) {
        state.stash.data = [
          ...state.stash.data.filter((i) => i.$id !== payload.$id),
          result.val,
        ];
      }

      state.app.isLoading = false;

      if (result.ok) {
        actions.app.notification.show({
          message: "Successfully 🤙",
          type: "success",
        });
        return createOk(result.val);
      } else {
        actions.app.notification.show({
          message: "Failed 🤷‍♂️",
          type: "error",
        });
        return createErr(result.err);
      }
    },
    delete: async (context: Context, payload: Stash) => {
      const { state, effects, actions } = context;
      state.app.isLoading = true;

      const result = await effects.data.stash.delete(payload);

      if (result.ok) {
        state.stash.data = [
          ...state.stash.data.filter((i) => i.$id !== payload.$id),
        ];
      }

      state.app.isLoading = false;

      if (result.ok) {
        actions.app.notification.show({
          message: "Successfully 🤙",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed 🤷‍♂️",
          type: "error",
        });
        return createErr(null);
      }
    },
  },
  orders: {
    get: async (context: Context) => {
      const { state, effects } = context;
      const result = await effects.data.orders.get();
      if (!result.ok) return;
      state.orders = {
        isLoading: false,
        data: result.val.documents,
        sum: result.val.sum,
      };
    },
    add: async (context: Context, payload: OrderNew) => {
      const { state, effects, actions } = context;
      state.app.isLoading = true;

      const result = await effects.data.orders.add(payload);

      if (result.ok) {
        state.orders.data.push(result.val);
      }

      state.app.isLoading = false;

      if (result.ok) {
        actions.app.notification.show({
          message: "Successfully 🤙",
          type: "success",
        });
        return createOk(result.val);
      } else {
        actions.app.notification.show({
          message: "Failed 🤷‍♂️",
          type: "error",
        });
        return createErr(result.err);
      }
    },
    update: async (context: Context, payload: Order) => {
      const { state, effects, actions } = context;
      state.app.isLoading = true;

      const result = await effects.data.orders.update(payload);

      if (result.ok) {
        state.orders.data = [
          ...state.orders.data.filter((i) => i.$id !== payload.$id),
          result.val,
        ];
      }

      state.app.isLoading = false;

      if (result.ok) {
        actions.app.notification.show({
          message: "Successfully 🤙",
          type: "success",
        });
        return createOk(result.val);
      } else {
        actions.app.notification.show({
          message: "Failed 🤷‍♂️",
          type: "error",
        });
        return createErr(result.err);
      }
    },
    delete: async (context: Context, payload: Order) => {
      const { state, effects, actions } = context;
      state.app.isLoading = true;

      const result = await effects.data.orders.delete(payload);

      if (result.ok) {
        state.orders.data = [
          ...state.orders.data.filter((i) => i.$id !== payload.$id),
        ];
      }

      state.app.isLoading = false;

      if (result.ok) {
        actions.app.notification.show({
          message: "Successfully 🤙",
          type: "success",
        });
        return createOk(null);
      } else {
        actions.app.notification.show({
          message: "Failed 🤷‍♂️",
          type: "error",
        });
        return createErr(null);
      }
    },
  },
};
