import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { useActions, useAppState } from "../../store";

const useStyles = makeStyles((theme) => ({
  snackbar: {
    bottom: `${theme.spacing(6)}px !important`,
    [theme.breakpoints.down("sm")]: {
      bottom: `${theme.spacing(12)}px !important`,
    },
  },
  alert: {
    width: "100%",
  },
}));

const AUTO_HIDE = 1000 * 3;

export const AppSnackbar = () => {
  const state = useAppState();
  const actions = useActions();
  const classes = useStyles();

  const onClose = () => actions.app.notification.close();

  return (
    <Snackbar
      className={classes.snackbar}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={state.app.notification.show}
      onClose={() => onClose()}
      autoHideDuration={AUTO_HIDE}
      disableWindowBlurListener
    >
      <Alert
        onClose={() => onClose()}
        severity={state.app.notification.type}
        className={classes.alert}
      >
        {state.app.notification.message}
      </Alert>
    </Snackbar>
  );
};
