import { Record, String } from "runtypes";

export const RtUser = Record({
  $id: String,
  name: String,
  email: String,
  prefs: Record({
    photoURL: String,
  }),
});
