import { Array, Null, Number, Record, String } from "runtypes";

export const RtStashNew = Record({
  title: String,
  stock: Number.Or(Null),
  notes: String.Or(Null),
});

export const RtStash = Record({
  ...RtStashNew.fields,
  $id: String,
  createdAt: String,
  lastUpdatedAt: String,
});

export const RtStashRequest = Record({
  documents: Array(RtStash),
  sum: Number,
});
