import { css } from "@emotion/css";
import Container from "@material-ui/core/Container";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import React from "react";
import { EntryNew } from "stashify-models/types/entry";
import { useActions, useAppState } from "../../store";
import { EntryFormLite } from "../EntryFormLite";

export const BottomDrawer = () => {
  const {
    app: {
      bottomDrawer: { open },
    },
    stash,
  } = useAppState();
  const actions = useActions();

  const onSubmit = async (entry: EntryNew) => {
    await actions.data.entries.add(entry);
    actions.app.bottomDrawer.toggle(false);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => actions.app.bottomDrawer.toggle(false)}
      onOpen={() => actions.app.bottomDrawer.toggle(true)}
      disableSwipeToOpen
    >
      <Container
        maxWidth="sm"
        className={css`
          padding-top: 16px;
          padding-bottom: 16px;
        `}
      >
        <EntryFormLite stash={stash.data} onSubmit={onSubmit} />
      </Container>
    </SwipeableDrawer>
  );
};
