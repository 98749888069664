import { Appwrite } from "appwrite";
import { APP_API_ENDPOINT, APP_API_PROJECT_ID, APP_HOST } from "./config";

// TODO
// if (!APP_API_ENDPOINT || !APP_API_PROJECT_ID) throw new Error("env missing");

const api = new Appwrite();
api.setEndpoint(APP_API_ENDPOINT).setProject(APP_API_PROJECT_ID);

// TODO
const createOAuth2Session = async () =>
  api.account.createOAuth2Session("google", `${APP_HOST}`, `${APP_HOST}`);

export const auth = {
  createOAuth2Session,
  deleteSessions: async () => api.account.deleteSessions(),
};

export { api };
