import { Array, Null, Number, Record, String } from "runtypes";

export const RtEntryNew = Record({
  stashId: String,
  notes: String.Or(Null),
  createdAt: String,
});

export const RtEntry = Record({
  ...RtEntryNew.fields,
  $id: String,
  lastUpdatedAt: String,
});

export const RtEntryRequest = Record({
  documents: Array(RtEntry),
  sum: Number,
});
